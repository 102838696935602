import './Home.css'

const Home = () => {
    return (
    <div class='Container'>
      <p class='Version-Control'>Version 3.2.12 Alpha - Last Updated: Dec 8, 2024 </p>
    </div>
    )
    ;
  };
  
  export default Home;
  