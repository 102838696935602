const Blogs = () => {
    return (
    <div>
      <h1>Blog coming soon! Short example below</h1>
      <h3>Staying Focused</h3>
      <h5>May 25, 2023</h5>
      <p>
      Make use of all the tools at your displosal.  Improve everyday.  Today's article focuses on staying consistent with development.  
      <br></br>
      There are going to be times where you need to be at your best.
      </p>
    </div>);
  };
  
  export default Blogs;
  